import {
	Box,
	Group,
	NumberInput,
	Select,
	Slider,
	Stack,
	createStyles,
} from '@mantine/core';
import { Icon, Text, Title } from '@repo/foundations';
import { useState } from 'react';
import {
	ScheduleCadence,
	ScheduleConfig,
} from '../../../../api/types/models/schedule';
import { THRESHOLD_OPTIONS } from '../../../../pages/MonitorPage/constants';
import HorizontalScheduleSelector from '../../../ScheduleSelector/HorizontalScheduleSelector';

const useStyles = createStyles((theme) => ({
	scheduleSelector: {
		fontSize: theme.fontSizes.sm,
		border: 'solid',
		borderWidth: 0.5,
		borderRadius: theme.radius.sm,
		borderColor: theme.other.getColor('border/input/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.xs}`,
	},
	scheduleSelectorWarning: {
		border: 'solid',
		borderWidth: 0.5,
		borderRadius: theme.radius.md,
		borderColor: theme.other.getColor('fill/transparent/active'),
		boxShadow: theme.shadows.md,
		padding: theme.spacing.sm,
	},
	scheduleSelectorWarningIcon: {
		backgroundColor: theme.other.getColor('fill/caution/default'),
		borderRadius: theme.radius.sm,
		padding: theme.spacing['3xs'],
	},
	sliderMarkLabel: {
		color: theme.other.getColor('text/primary/default'),
	},
}));

function ConfigureStack() {
	const { classes } = useStyles();

	const [schedule, setSchedule] = useState<ScheduleConfig>({
		cadence: ScheduleCadence.DAILY,
		frequency: 1,
		hour: 0,
		day: 0,
	});
	const [threshold, setThreshold] = useState<'automatic' | 'manual'>(
		'automatic'
	);
	const [sliderValue, setSliderValue] = useState(5);

	const [minValue, setMinValue] = useState<number | '' | undefined>(undefined);
	const [maxValue, setMaxValue] = useState<number | '' | undefined>(undefined);

	return (
		<Stack p={0} spacing="md">
			<Title size="md">Configure</Title>
			<Stack p={0} spacing="sm">
				<Stack p={0} spacing="xs">
					<Text size="sm" weight="semibold">
						Schedule
					</Text>
					<HorizontalScheduleSelector
						schedule={schedule}
						onScheduleUpdate={async (props) => setSchedule(props)}
						className={classes.scheduleSelector}
					/>
				</Stack>
				{schedule.cadence === ScheduleCadence.WEEKLY && (
					<Group noWrap className={classes.scheduleSelectorWarning}>
						<Box className={classes.scheduleSelectorWarningIcon}>
							<Icon name="alertTriangle" color="text/caution-on-fill/default" />
						</Box>
						<Text size="sm">
							Scheduling a weekly monitor may prolong automatic threshold
							determination. For faster results, consider manual thresholds or
							daily runs.
						</Text>
					</Group>
				)}
				<Stack p={0} spacing="xs">
					<Select
						label="Threshold"
						defaultValue={threshold}
						onChange={(value) => setThreshold(value as 'automatic' | 'manual')}
						data={THRESHOLD_OPTIONS}
					/>
					{threshold === 'automatic' ? (
						<>
							<Text size="sm" color="text/secondary/default">
								Automatic thresholds use your monitor&apos;s historical data to
								adjust limits, improving accuracy progressively over time.
							</Text>
							<Slider
								classNames={{
									markLabel: classes.sliderMarkLabel,
								}}
								pb="lg"
								w="100%"
								color="dark"
								label={null}
								showLabelOnHover={false}
								step={1}
								min={1}
								max={10}
								value={sliderValue}
								onChange={setSliderValue}
								onChangeEnd={setSliderValue}
								marks={[
									{ value: 1.05, label: 'Low' },
									{ value: 5, label: 'Default' },
									{ value: 9.9, label: 'High' },
								]}
							/>
							<Text size="sm" color="text/secondary/default">
								If adjusted, higher sensitivity catches smaller details, while
								lower sensitivity focuses on bigger changes.
							</Text>
						</>
					) : (
						<Group w="100%" grow>
							<Stack p={0} spacing="xs" w="100%">
								<Text size="sm" weight="semibold">
									Minimum
								</Text>
								<NumberInput
									hideControls
									defaultValue={''}
									value={minValue}
									onChange={setMinValue}
								/>
							</Stack>
							<Stack p={0} spacing="xs" w="100%">
								<Text size="sm" weight="semibold">
									Minimum
								</Text>
								<NumberInput
									hideControls
									defaultValue={''}
									value={maxValue}
									onChange={setMaxValue}
								/>
							</Stack>
						</Group>
					)}
				</Stack>
			</Stack>
			<Stack p={0} spacing="xs" />
		</Stack>
	);
}

export default ConfigureStack;
