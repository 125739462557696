import { type SelectItem } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { useApiListTeams } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { MultiSelect, MultiSelectProps } from '@repo/foundations';

type TeamItem = SelectItem & {
	team: TeamOut;
};

export function MultiTeamsSelector<T extends SelectItem = SelectItem>({
	value,
	setValue,
	...rest
}: {
	value: string[];
	setValue: (x: string[]) => void;
} & Omit<
	MultiSelectProps<T>,
	'data' | 'value' | 'setValue' | 'renderIcon' | 'renderLabel'
>) {
	const { data } = useApiListTeams(
		{},
		{
			suspense: false,
		}
	);

	const items: TeamItem[] = (data?.results || []).map((team) => ({
		value: team.id,
		label: team.name,
		team,
	}));

	return (
		<MultiSelect
			{...rest}
			value={value}
			setValue={setValue}
			data={items}
			placeholder="Select"
			showRightSection
			renderLabel={(item) => item.team.name}
			renderIcon={(item) => <IconWrapper>{item.team.icon}</IconWrapper>}
		/>
	);
}
